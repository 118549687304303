import { ChainId, Currency, currencyEquals, ETHER, Token } from 'titano-sdk3'
import { Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

import { SUGGESTED_BASES } from 'config/constants/exchange'
import { AutoColumn } from '../Layout/Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  background-color: ${({ theme }) => theme.colors.inputPanelBackground};
  box-shadow: 12px 12px 35px rgba(96, 96, 96, 0.15);
  border-radius: 10px;
  display: flex;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  height: 34px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.htmlBackground};
  }

  opacity: ${({ disable }) => disable && '0.4'};
`

const StyledSoftText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <StyledSoftText fontSize="12px">{t('Common bases')}</StyledSoftText>
        <QuestionHelper size="12px" text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
      </AutoRow>
      <AutoRow gap="auto">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo size="20px" currency={ETHER} style={{ marginRight: 8 }} />
          <Text fontSize="10px">BNB</Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo size="20px" currency={token} style={{ marginRight: 8, borderRadius: '50%' }} />
              <Text fontSize="10px">{token.symbol}</Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </AutoColumn>
  )
}
