function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { scales, variants } from "./types";
var _obj;
export var scaleVariants = (_obj = {}, _defineProperty(_obj, scales.MD, {
    padding: "5.5px 11px",
    fontSize: "10px"
}), _defineProperty(_obj, scales.SM, {
    padding: "0 4px",
    fontSize: "12px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _defineProperty(_obj1, variants.PRIMARY, {
    backgroundColor: "primary"
}), _defineProperty(_obj1, variants.SECONDARY, {
    backgroundColor: "secondary"
}), _defineProperty(_obj1, variants.SUCCESS, {
    backgroundColor: "success"
}), _defineProperty(_obj1, variants.TEXTDISABLED, {
    backgroundColor: "textDisabled"
}), _defineProperty(_obj1, variants.TEXTSUBTLE, {
    backgroundColor: "textSubtle"
}), _defineProperty(_obj1, variants.BINANCE, {
    backgroundColor: "binance"
}), _defineProperty(_obj1, variants.FAILURE, {
    backgroundColor: "failure"
}), _defineProperty(_obj1, variants.WARNING, {
    backgroundColor: "warning"
}), _obj1);
